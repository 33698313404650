import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { AuthService } from './auth.service';

export function provideAuthInitFactory(): FactoryProvider {
  return {
    provide: APP_INITIALIZER,
    useFactory: (_: AuthService) => () => {},
    deps: [AuthService],
    multi: true,
  };
}
