import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  filter,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AuthProfile } from './auth.service';
import { CookieService } from '@shared/services/cookie.service';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  protected accessTokenSubject = new BehaviorSubject<string | null>(null);
  protected refreshTokenSubject = new BehaviorSubject<string | null>(null);
  protected profileSubject = new BehaviorSubject<AuthProfile | null>(null);

  accessToken$ = this.accessTokenSubject.asObservable();
  refreshToken$ = this.refreshTokenSubject.asObservable();
  profile$ = this.profileSubject.asObservable();

  constructor(
    private readonly cookieService: CookieService,
    private authApi: AuthApiService
  ) {
    this.loadTokenFromCookie();
  }

  get profile(): AuthProfile | null {
    return this.profileSubject.value;
  }

  setToken(access: string | null, refresh?: string | null): void {
    this.accessTokenSubject.next(access);

    if (refresh) this.refreshTokenSubject.next(refresh);
  }

  setProfile(value: AuthProfile | null): void {
    this.profileSubject.next(value);
  }

  protected loadTokenFromCookie(): void {
    const cookieToken = this.cookieService.getCookie('token');

    if (cookieToken) {
      this.accessTokenSubject.next(cookieToken);
    }
  }

  refresh(): Observable<unknown> {
    return this.refreshToken$.pipe(
      take(1),
      switchMap((refreshToken) => {
        if (!refreshToken) return EMPTY;

        return this.authApi.refresh({ refreshToken });
      }),
      filter((res) => !!res.accessToken),
      tap((res) => this.setToken(res.accessToken, res.refreshToken))
    );
  }
}
