import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthApiService } from './auth-api.service';
import { provideAuthInitFactory } from './provide-auth-init-factory';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from './auth-token.interceptor';
import { AuthStoreService } from './auth-store.service';
import { CookieService } from '@shared/services/cookie.service';
import { AuthGuard } from './auth.guard';

@NgModule({
  providers: [
    AuthService,
    AuthApiService,
    AuthStoreService,
    CookieService,
    AuthGuard,
    provideAuthInitFactory(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
