import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TitleWatcherService } from './title-watcher.service';
import { TITLE_WATCHER_INIT_FACTORY } from './title-watcher-init.factory';

@NgModule({
  providers: [
    TitleWatcherService,
    {
      provide: APP_INITIALIZER,
      useFactory: TITLE_WATCHER_INIT_FACTORY,
      multi: true,
      deps: [TitleWatcherService],
    },
  ],
})
export class TitleModule {}
