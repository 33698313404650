import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { AuthStoreService } from './auth-store.service';
import { AuthApiService } from './auth-api.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authApi: AuthApiService,
    private readonly authStore: AuthStoreService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const id = route.queryParams['tid'];

    this.authApi.tid = id;

    return this.authApi.getToken({ id }).pipe(
      map((response) => response.token),
      tap((token) => this.authStore.setToken(token)),
      map(Boolean)
    );
  }
}
