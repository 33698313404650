import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthProfile } from './auth.service';
import { environment } from '@environment';
import { AuthGetTokenRequestDto } from './dtos/auth-get-token.request.dto';
import { AuthGetTokenResponseDto } from './dtos/auth-get-token.response.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  tid: string | null = null;

  constructor(private readonly http: HttpClient) {}

  getProfile(): Observable<AuthProfile> {
    return this.http.get<AuthProfile>(
      `${environment.baseUrl}/tes-hub/auth/get-user`
    );
  }

  getToken(
    request: AuthGetTokenRequestDto
  ): Observable<AuthGetTokenResponseDto> {
    return this.http.post<AuthGetTokenResponseDto>(
      `${environment.baseUrl}/tes-hub/auth/getToken`,
      request
    );
  }

  refresh(request: { refreshToken: string }) {
    return this.http.post<{
      accessToken: string;
      refreshToken: string;
      tokenType: string;
      expiresAt: string;
    }>(`${environment.baseUrl}/tes-hub/auth/token`, request);
  }
}
