import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface CookieOptions {
  /** Делает куки видимым только по указанному пути и ниже. */
  path?: string;
  /** устанавливает дату истечения срока действия, без них куки умрёт при закрытии браузера */
  expires?: Date | string | number;
  /** устанавливает дату истечения срока действия, без них куки умрёт при закрытии браузера */
  'max-age'?: string | number;
  /** по умолчанию куки видно только на текущем домене, если явно указан домен, то куки видно и на поддоменах */
  domain?: string;
  /** делает куки доступным только при использовании HTTPS */
  secure?: boolean;
  /** запрещает браузеру отправлять куки с запросами, поступающими извне, помогает предотвратить XSRF-атаки. */
  samesite?: string;
  [key: string]: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  getCookie(name: string): string | undefined {
    let matches = this.document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setCookie(
    name: string,
    value: string,
    additionalOptions: CookieOptions = {}
  ): void {
    const options: CookieOptions = {
      path: '/',
      ...additionalOptions,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    this.document.cookie = updatedCookie;
  }

  deleteCookie(name: string): void {
    this.setCookie(name, '', {
      'max-age': -1,
    });
  }
}
