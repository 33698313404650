enum FirebaseAnalyticsKeys {
  FIREBASE_EVENT_ORIGIN_KEY = 'firebase_event_origin',
  FIREBASE_PREVIOUS_SCREEN_CLASS_KEY = 'firebase_previous_class',
  FIREBASE_PREVIOUS_SCREEN_INSTANCE_ID_KEY = 'firebase_previous_id',
  FIREBASE_PREVIOUS_SCREEN_NAME_KEY = 'firebase_previous_screen',
  FIREBASE_SCREEN_CLASS_KEY = 'firebase_screen_class',
  FIREBASE_SCREEN_INSTANCE_ID_KEY = 'firebase_screen_id',
  FIREBASE_SCREEN_NAME_KEY = 'firebase_screen',
  OUTLET_KEY = 'outlet',
  PAGE_PATH_KEY = 'page_path',
  PAGE_TITLE_KEY = 'page_title',
  SCREEN_CLASS_KEY = 'screen_class',
  SCREEN_NAME_KEY = 'screen_name',
  SCREEN_VIEW_EVENT = 'screen_view',
  EVENT_ORIGIN_AUTO = 'auto',
  SCREEN_INSTANCE_DELIMITER = '#',
}

export default FirebaseAnalyticsKeys;
