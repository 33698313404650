import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AuthStoreService } from './auth-store.service';
import { AuthService } from './auth.service';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  private isRefreshing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly authStore: AuthStoreService,
    private authApi: AuthApiService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.url.includes('/getToken')) {
      return next.handle(req);
    }

    if (this.authApi.tid) {
      return this.authApi.getToken({ id: this.authApi.tid }).pipe(
        take(1),
        map((res) => res.token),
        map((token) => this.addToken(req, token)),
        concatMap((req) => next.handle(req))
        // catchError((event) => {
        //   if (event.status === 401 && event.error.message === 'jwt expired') {
        //     if (!this.isRefreshing$.getValue()) {
        //       this.isRefreshing$.next(true);
        //       return this.authStore.refresh().pipe(
        //         take(1),
        //         tap(() => console.log('ready')),
        //         tap(() => this.isRefreshing$.next(false)),
        //         concatMap(() => this.authStore.accessToken$.pipe(take(1))),
        //         map((accessToken) => this.addToken(req, accessToken)),
        //         concatMap((req) => next.handle(req))
        //       );
        //     }

        //     return this.isRefreshing$.pipe(
        //       filter((is) => !is),
        //       take(1),
        //       switchMap(() => this.authStore.accessToken$),
        //       switchMap((token) => {
        //         return next.handle(this.addToken(req, token));
        //       })
        //     );
        //   }

        //   return of(event);
        // })
      );
    }

    return next.handle(req);
  }

  addToken(
    req: HttpRequest<unknown>,
    token: string | null
  ): HttpRequest<unknown> {
    const alreadyHasToken = !!req.headers.get('authorization');

    if (req.body && 'refreshToken' in (req.body as any)) return req;

    return !token || alreadyHasToken
      ? req
      : req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
