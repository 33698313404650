import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleModule } from './title/title.module';
import { AuthModule } from './auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { NavigationModule } from './navigation/navigation.module';

@NgModule({
  imports: [
    CommonModule,
    TitleModule,
    AuthModule,
    HttpClientModule,
    NavigationModule,
  ],
})
export class CoreModule {}
