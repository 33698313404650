import { Injectable } from '@angular/core';
import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthApiService } from './auth-api.service';
import { AuthStoreService } from './auth-store.service';

export interface AuthProfile {
  id: string;
  email: string;
  phone: string;
  firstName?: string;
  lastName?: string;
  patronymic?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authStore: AuthStoreService,
    private readonly authApi: AuthApiService
  ) {
    this.activatedRoute.queryParams
      .pipe(
        map((params) => params['token']),
        filter((token) => !!token)
      )
      .subscribe((token) => this.authStore.setToken(token));

    /*
      Если роут не требует авторизации, но передаётся tid
      запрашиваем токен, чтобы tid больше не был доступен
     */
    this.activatedRoute.queryParams
      .pipe(
        map((params) => params['tid']),
        withLatestFrom(this.authStore.accessToken$),
        filter(([id, token]) => !!id && !token),
        switchMap(([id]) =>
          this.authApi.getToken({ id }).pipe(
            map((response) => [response.token, response.refreshToken]),
            catchError(() => EMPTY)
          )
        ),
        filter((token) => token && !!token[0])
      )
      .subscribe(([acc, ref]) => this.authStore.setToken(acc, ref));

    this.authStore.accessToken$
      .pipe(
        distinctUntilChanged(),
        switchMap((token) => (token ? this.authApi.getProfile() : of(null)))
      )
      .subscribe((profile) => this.authStore.setProfile(profile));
  }
}
