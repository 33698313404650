import {
  distinctUntilChanged,
  filter,
  groupBy,
  map,
  mergeMap,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { initializeApp, FirebaseApp, FirebaseOptions } from 'firebase/app';
import {
  getAnalytics,
  logEvent,
  Analytics,
  isSupported,
  AnalyticsCallOptions,
} from 'firebase/analytics';
import {
  ComponentFactoryResolver,
  Injectable,
  NgZone,
  OnDestroy,
  Optional,
} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '@environment';
import { ActivationEnd, Router, ɵEmptyOutletComponent } from '@angular/router';
import FirebaseAnalyticsKeys from './enums';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, ReplaySubject, of } from 'rxjs';
import {
  IScreenViewEvent,
  NavigationAnalyticsParams,
} from './models.interface';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDFW60-ULMgTDulwUwcaADYL1iQVT4MaUs',
  authDomain: 'tes-store-83616.firebaseapp.com',
  projectId: 'tes-store-83616',
  storageBucket: 'tes-store-83616.appspot.com',
  messagingSenderId: '52291429504',
  appId: '1:52291429504:web:52cc2591dddfae391ff265',
  measurementId: 'G-WH40TYRRVP',
};

@Injectable()
export class AnalyticsService implements OnDestroy {
  public analytics$ = new BehaviorSubject<Analytics | null>(null);
  public analyticsReady$ = new ReplaySubject<boolean>(1);
  private app: FirebaseApp | undefined;
  private analyticsSub: Subscription | undefined;
  private disposable: Subscription | undefined;
  // this is an INT64 in iOS/Android but use INT32 cause TS
  private nextScreenInstanceID =
    Math.floor(Math.random() * (2 ** 32 - 1)) - 2 ** 31;
  private knownScreenInstanceIDs: Record<string, number> = {};

  constructor(
    private readonly zone: NgZone,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    @Optional() router: Router,
    @Optional() title: Title
  ) {
    if (!environment.isAnalyticsEnabled || !environment.production) {
      this.analyticsReady$.next(true);
      this.analyticsReady$.complete();
      return;
    }

    this.app = initializeApp(firebaseConfig);
    this.initAnalytics()
      .pipe(take(1))
      .subscribe((analytics) => {
        this.analytics$.next(analytics);
        this.analyticsReady$.next(true);
        this.analyticsReady$.complete();
      });

    return; // TODO: доделать когда будем внедрять полную аналитику
    // Отслеживает переходы между страницами
    this.analytics$.pipe(take(1)).subscribe((analytics) => {
      if (analytics && router) {
        this.initScreenDetection(
          this.zone,
          router,
          title,
          this.componentFactoryResolver
        );
      }
    });
  }

  private initAnalytics(): Observable<Analytics | null> {
    return new Observable<Analytics | null>((observer) => {
      isSupported()
        .then(() => {
          const analytics = getAnalytics(this.app);
          observer.next(analytics);
          observer.complete();
        })
        .catch((error) => {
          console.warn(`Analytics is not supported, error: ${error}`);
          observer.next(null);
          observer.complete();
        });
    }).pipe(take(1), shareReplay(1));
  }

  logEvent(
    eventName: string,
    eventParams?: { [key: string]: any },
    options?: AnalyticsCallOptions
  ): void {
    const analyticsInstance = this.analytics$.getValue();

    if (
      environment.production &&
      environment.isAnalyticsEnabled &&
      analyticsInstance
    ) {
      logEvent(analyticsInstance, eventName, eventParams, options);
    } else if (!environment.production && environment.isAnalyticsEnabled) {
      console.group('Analytics Event Log');
      console.log(
        '%cEvent Name:',
        'color: green; font-weight: bold;',
        eventName
      );
      console.log(
        '%cEvent Params:',
        'color: orange; font-weight: bold;',
        eventParams
      );
      console.log('%cOptions:', 'color: purple; font-weight: bold;', options);
      console.groupEnd();
    }
  }

  private screenViewEvent = (
    router: Router,
    title: Title | null,
    componentFactoryResolver: ComponentFactoryResolver
  ): Observable<IScreenViewEvent> => {
    const activationEndEvents = router.events.pipe(
      filter((event): event is ActivationEnd => event instanceof ActivationEnd)
    );
    return activationEndEvents.pipe(
      switchMap<ActivationEnd, Observable<Record<string, any> | null>>(
        (activationEnd) => {
          const urlTree = router.parseUrl(
            router.url.replace(/(?:\().+(?:\))/g, (a) =>
              a.replace('://', ':///')
            )
          );
          const pagePath =
            urlTree.root.children[activationEnd.snapshot.outlet]?.toString() ||
            '';
          const actualSnapshot = router.routerState.root.children
            .map((it) => it)
            .find((it) => it.outlet === activationEnd.snapshot.outlet);

          if (!actualSnapshot) {
            return of(null);
          }

          let actualDeep = actualSnapshot;
          while (actualDeep.firstChild) {
            actualDeep = actualDeep.firstChild;
          }
          const screenName =
            actualDeep.pathFromRoot
              .map((s) => s.routeConfig?.path)
              .filter((it) => it)
              .join('/') || '/';

          const params: NavigationAnalyticsParams = {
            [FirebaseAnalyticsKeys.SCREEN_NAME_KEY]: screenName,
            [FirebaseAnalyticsKeys.PAGE_PATH_KEY]: `/${pagePath}`,
            [FirebaseAnalyticsKeys.FIREBASE_EVENT_ORIGIN_KEY]:
              FirebaseAnalyticsKeys.EVENT_ORIGIN_AUTO,
            [FirebaseAnalyticsKeys.FIREBASE_SCREEN_NAME_KEY]: screenName,
            [FirebaseAnalyticsKeys.OUTLET_KEY]: activationEnd.snapshot.outlet,
          };

          if (title) {
            params[FirebaseAnalyticsKeys.PAGE_TITLE_KEY] = title.getTitle();
          }

          let component = actualSnapshot.component;
          if (component) {
            if (component === ɵEmptyOutletComponent) {
              let deepSnapshot = activationEnd.snapshot;
              while (deepSnapshot.firstChild) {
                deepSnapshot = deepSnapshot.firstChild;
              }
              component = deepSnapshot.component;
            }
          } else {
            component = activationEnd.snapshot.component;
          }

          if (typeof component === 'string') {
            return of({
              ...params,
              [FirebaseAnalyticsKeys.SCREEN_CLASS_KEY]: component,
            });
          } else if (component) {
            const componentFactory =
              componentFactoryResolver.resolveComponentFactory(component);
            return of({
              ...params,
              [FirebaseAnalyticsKeys.SCREEN_CLASS_KEY]:
                componentFactory.selector,
            });
          }
          return of(null);
        }
      ),
      filter((it): it is NavigationAnalyticsParams => !!it),
      map((params: NavigationAnalyticsParams) => ({
        [FirebaseAnalyticsKeys.FIREBASE_SCREEN_CLASS_KEY]:
          params[FirebaseAnalyticsKeys.SCREEN_CLASS_KEY],
        [FirebaseAnalyticsKeys.FIREBASE_SCREEN_INSTANCE_ID_KEY]:
          this.getScreenInstanceID(params),
        ...params,
      })),
      groupBy((it) => it[FirebaseAnalyticsKeys.OUTLET_KEY]),
      mergeMap((it) =>
        it.pipe(
          distinctUntilChanged(
            (a, b) => JSON.stringify(a) === JSON.stringify(b)
          ),
          startWith<any, any>(undefined),
          pairwise(),
          map(([prior, current]) =>
            prior
              ? {
                  [FirebaseAnalyticsKeys.FIREBASE_PREVIOUS_SCREEN_CLASS_KEY]:
                    prior[FirebaseAnalyticsKeys.SCREEN_CLASS_KEY],
                  [FirebaseAnalyticsKeys.FIREBASE_PREVIOUS_SCREEN_NAME_KEY]:
                    prior[FirebaseAnalyticsKeys.SCREEN_NAME_KEY],
                  [FirebaseAnalyticsKeys.FIREBASE_PREVIOUS_SCREEN_INSTANCE_ID_KEY]:
                    prior[
                      FirebaseAnalyticsKeys.FIREBASE_SCREEN_INSTANCE_ID_KEY
                    ],
                  ...current,
                }
              : current
          )
        )
      )
    );
  };

  private initScreenDetection(
    zone: NgZone,
    router: Router,
    title: Title | null,
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    zone.runOutsideAngular(() => {
      //TODO: пока в зоне, надо посмотреть как вынести
      this.disposable = this.screenViewEvent(
        router,
        title,
        componentFactoryResolver
      )
        .pipe(
          switchMap(async (params) => {
            return this.logEvent(
              FirebaseAnalyticsKeys.SCREEN_VIEW_EVENT,
              params
            );
          })
        )
        .subscribe();
    });
  }

  private getScreenInstanceID(params: Record<string, any>) {
    // unique the screen class against the outlet name
    const screenInstanceKey = [
      params[FirebaseAnalyticsKeys.SCREEN_CLASS_KEY],
      params[FirebaseAnalyticsKeys.OUTLET_KEY],
    ].join(FirebaseAnalyticsKeys.SCREEN_INSTANCE_DELIMITER);
    if (this.knownScreenInstanceIDs.hasOwnProperty(screenInstanceKey)) {
      return this.knownScreenInstanceIDs[screenInstanceKey];
    } else {
      const ret = this.nextScreenInstanceID++;
      this.knownScreenInstanceIDs[screenInstanceKey] = ret;
      return ret;
    }
  }

  ngOnDestroy(): void {
    this.analyticsSub?.unsubscribe();
    this.disposable?.unsubscribe();
  }
}
